import React from 'react'

import styles from './footer.module.css'

const Footer = (props) => {
  return (
    <footer className={styles['footer']}>
      <div className={styles['container']}>
        <div className={styles['container1']}>
          <span className={styles['text']}>JBTech</span>
          <span>Copyright © 2023 by Creative Tim.</span>
        </div>
        <div className={styles['container2']}></div>
      </div>
      <img
        alt="image"
        src="/playground_assets/waves-white.svg"
        className={styles['image']}
      />
    </footer>
  )
}

export default Footer
