import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import PrimaryPinkButton from '../components/primary-pink-button'
import PrimaryBlueButton from '../components/primary-blue-button'
import Footer from '../components/footer'
import projectStyles from '../style.module.css'
import styles from './index.module.css'

const Home = (props) => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>JBTech UI</title>
        <meta property="og:title" content="JBTech UI" />
      </Helmet>
      <Header></Header>
      <div className={styles['hero']}>
        <div className={styles['container01']}>
          <div className={styles['card']}>
            <h1 className={styles['text']}>IT Consulting Experts</h1>
            <h1
              className={` ${styles['text01']} ${projectStyles['heading-one']} `}
            >
              Choose the best
            </h1>
            <span className={styles['text02']}>
              JBTech is a IT consulting business in Jakarta. offering
              comprehensive IT services from consulting and design to
              implementation and support. Our experienced team of professionals
              provide customized solutions to meet all your IT needs. Let us
              help you maximize your IT investments and reach your business
              goals.
            </span>
            <div className={styles['container02']}>
              <div className={styles['container03']}>
                <PrimaryPinkButton button="contact us"></PrimaryPinkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        alt="image"
        src="/playground_assets/curved6-1500h.jpg"
        className={styles['image']}
      />
      <section className={styles['container04']}>
        <div className={styles['container05']}>
          <h1
            className={` ${styles['text03']} ${projectStyles['heading-one']} `}
          >
            <span className={styles['text04']}>From nothing to something</span>
          </h1>
          <span className={styles['text05']}>
            <span className={styles['text06']}>
              The highest status people in human history are those that asked
              for nothing and gave everything
            </span>
          </span>
        </div>
        <div className={styles['container06']}>
          <div className={styles['container07']}>
            <img
              alt="image"
              src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/laptop.jpg"
              className={styles['image01']}
            />
            <span className={` ${styles['text07']} ${projectStyles['small']} `}>
              <span className={styles['text08']}>
                &quot;Over the span of the satellite record, Arctic sea ice has
                been declining significantly, while sea ice in the Antarctichas
                increased very slightly&quot;
              </span>
              <br></br>
              <span className={styles['text10']}>-NOAA</span>
            </span>
            <div className={styles['container08']}>
              <img
                alt="image"
                src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/coding.jpg"
                className={styles['image02']}
              />
            </div>
          </div>
          <div className={styles['container09']}>
            <img
              alt="image"
              src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/tasks.jpg"
              className={styles['image03']}
            />
            <div className={styles['container10']}>
              <h3 className={projectStyles['heading-two']}>The Services</h3>
              <p>
                <br className={styles['text13']}></br>
                <span className={styles['text14']}>Cloud Services</span>
                <br></br>
                <span>
                  A Services that allows business to store, manage, and process
                  data on remote servers hosted on the Internet
                </span>
                <br></br>
                <br></br>
                <span>Managed Services</span>
                <br></br>
                <span>
                  A Services that helps business to maintaining, and
                  anticipating need for, a range processes and functions,
                  ostensibly for the purposes of improved operations.
                </span>
                <br></br>
                <br></br>
                <span>IT Infrastructure Design</span>
                <br></br>
                <span>
                  A Service that provides business with custom design IT
                  solutions to meet their specific requirements
                </span>
                <br></br>
                <br></br>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={styles['testimonials']}>
        <div className={styles['container11']}>
          <div className={styles['container12']}>
            <div className={styles['container13']}>
              <h2
                className={` ${styles['text29']} ${projectStyles['heading-one']} `}
              >
                Work with us
              </h2>
              <p className={` ${styles['text30']} ${projectStyles['lead']} `}>
                <span className={styles['text31']}>
                  Whatever your qualification is - we got you!
                </span>
              </p>
              <p className={` ${styles['text32']} ${projectStyles['body']} `}>
                <span className={styles['text33']}>
                  &quot;Take up one idea. Make that one idea your life - think
                  of it, dream of it, live on that idea. Let the brain, muscles,
                  nerves, every part of your body, be full of that idea, and
                  just leave every other idea alone. This is the way to success.
                  A single rose can be my garden... a single friend, my
                  world.&quot;
                </span>
              </p>
              <div className={styles['container14']}>
                <img
                  alt="image"
                  src="/playground_assets/team-4-200h.jpg"
                  className={styles['image04']}
                />
                <div className={styles['container15']}></div>
                <img
                  alt="image"
                  src="/playground_assets/team-3-200h.jpg"
                  className={styles['image05']}
                />
                <div className={styles['container16']}></div>
                <img
                  alt="image"
                  src="/playground_assets/team-2-200h.jpg"
                  className={styles['image06']}
                />
              </div>
            </div>
          </div>
          <div className={styles['logos']}>
            <div className={styles['container17']}>
              <div className={styles['container18']}>
                <div className={styles['container19']}>
                  <img
                    alt="image"
                    src="/playground_assets/logo-asana.svg"
                    className={styles['image07']}
                  />
                </div>
                <div className={styles['container20']}>
                  <img
                    alt="image"
                    src="/playground_assets/logo-slack.svg"
                    className={styles['image08']}
                  />
                </div>
                <div className={styles['container21']}>
                  <img
                    alt="image"
                    src="/playground_assets/logo-google-drive.svg"
                    className={styles['image09']}
                  />
                </div>
              </div>
              <div className={styles['container22']}>
                <div className={styles['container23']}>
                  <img
                    alt="image"
                    src="/playground_assets/logo-shopify.svg"
                    className={styles['image10']}
                  />
                </div>
                <div className={styles['container24']}>
                  <img
                    alt="image"
                    src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-apple.svg"
                    className={styles['image11']}
                  />
                </div>
                <div className={styles['container25']}>
                  <img
                    alt="image"
                    src="/playground_assets/logo-invision.svg"
                    className={styles['image12']}
                  />
                </div>
              </div>
              <div className={styles['container26']}>
                <div className={styles['container27']}>
                  <img
                    alt="image"
                    src="/playground_assets/logo-attlasian.svg"
                    className={styles['image13']}
                  />
                </div>
                <div className={styles['container28']}>
                  <img
                    alt="image"
                    src="/playground_assets/logo-weave.svg"
                    className={styles['image14']}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          alt="image"
          src="/playground_assets/bottom.svg"
          className={styles['bottom-wave-image']}
        />
        <img
          alt="image"
          src="/playground_assets/waves-white.svg"
          className={styles['image15']}
        />
        <img
          alt="image"
          src="/playground_assets/top.svg"
          className={styles['top-wave-image']}
        />
      </section>
      <div className={styles['contact']}>
        <div className={styles['container29']}>
          <div className={styles['form']}>
            <h2
              className={` ${styles['text34']} ${projectStyles['heading-one']} `}
            >
              <span>Say Hi!</span>
            </h2>
            <span className={` ${styles['text36']} ${projectStyles['lead']} `}>
              We&apos;d like to talk with you.
            </span>
            <form className={styles['form1']}>
              <label
                className={` ${styles['text37']} ${projectStyles['label']} `}
              >
                My name is
              </label>
              <input
                type="text"
                placeholder="Full Name"
                className={` ${styles['textinput']} ${projectStyles['small']} ${projectStyles['input']} `}
              />
              <label
                className={` ${styles['text38']} ${projectStyles['label']} `}
              >
                I&apos;m looking for
              </label>
              <input
                type="text"
                placeholder="What you love"
                className={` ${styles['textinput1']} ${projectStyles['small']} ${projectStyles['input']} `}
              />
              <label
                className={` ${styles['text39']} ${projectStyles['label']} `}
              >
                Your message
              </label>
              <textarea
                rows="8"
                placeholder="I want to say that..."
                className={` ${styles['textarea']} ${projectStyles['textarea']} ${projectStyles['small']} `}
              ></textarea>
            </form>
            <div className={styles['container30']}>
              <div className={styles['container31']}>
                <a
                  href="mailto:info@jbtech.co.id?subject=Hi"
                  className={styles['link']}
                >
                  <PrimaryBlueButton
                    button="send message"
                    className={styles['component2']}
                  ></PrimaryBlueButton>
                </a>
              </div>
            </div>
          </div>
          <div className={styles['info']}>
            <div className={styles['container32']}>
              <h3 className={projectStyles['heading-two']}>
                <span>
                  Contact
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>Information</span>
              </h3>
              <span className={styles['text43']}>
                Fill up the form and our Team will get back to you within 24
                hours.
              </span>
              <div className={styles['container33']}>
                <div className={styles['container34']}>
                  <svg viewBox="0 0 1024 1024" className={styles['icon']}>
                    <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                  </svg>
                  <a
                    href="mailto:hello@creative-tim.com?subject="
                    className={` ${styles['link1']} ${projectStyles['small']} `}
                  >
                    info@jbtech.co.id
                  </a>
                </div>
                <div className={styles['container35']}>
                  <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                    <path d="M512 490q44 0 75-31t31-75-31-75-75-31-75 31-31 75 31 75 75 31zM512 86q124 0 211 87t87 211q0 62-31 142t-75 150-87 131-73 97l-32 34q-12-14-32-37t-72-92-91-134-71-147-32-144q0-124 87-211t211-87z"></path>
                  </svg>
                  <span
                    className={` ${styles['text44']} ${projectStyles['small']} `}
                  >
                    Jakarta, Indonesia
                  </span>
                </div>
              </div>
            </div>
            <img
              alt="image"
              src="/playground_assets/wave-1.svg"
              className={styles['image16']}
            />
            <div className={styles['container36']}></div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Home
